
.navbar-logo {
	width: 200px;
}
.navbar-logo > img {
	/* width: 100px; */
	height: 100px;
	margin: 40px;
}
.certificate-title >a {
	/* text-xsdecoration: none; */
	color: inherit;
}

.certificate-container {
	display: flex;
	/* background-color: rgb(255, 157, 157); */
	flex-direction: column;
	align-items: center;
}

.certificate-content-container {
	display: flex;
	flex-direction: column;
	width: 600px;
	gap: 30px;
}
.certificate-owner-name {
	margin-block-end: .5em;
}
.certificate-container-image > img {
	width: 100%;
	border-radius: 20px;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}
.certificate-container-image > img:hover {
	width: 100%;
	border-radius: 20px;
}

.certificate-owner-container {
	background-color: #FFE9D9;
	border-radius: 10px;
	padding: 40px;
	line-height: 130%;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}
.art-print-date {
	margin-block-start: .5em;
}
.pkey {
	font-size: 14px;
}


.certificate-about-container {
	background-color: #FFE9D9;
	border-radius: 10px;
	padding: 40px;
	line-height: 100%;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}
.about-attribute-container {
	background-color: #FFE9D9;
	border-radius: 10px;
	padding: 40px;
	line-height: 100%;
	-webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	-moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
	box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.1);
}

.about-attribute-content-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 15px;
	margin-top: 40px;
}

.about-attribute-content {
	width: 140px;
	/* height: 75px; */
	background-color: #FAD7BB;
	border-radius: 10px;
	padding: 10px;
}
.about-address-title {
	margin-block-end: .5em;
}

.about-attribute-content-trait {
	font-size: 11px;
	text-transform: uppercase;
	letter-spacing: .03rem;
	font-style: italic;
	/* margin-left: .3rem; */
}
.about-attribute-content-value {
	text-align: center;
	font-size: 18px;
	letter-spacing: .03rem;
	padding: 0.7em;
	padding-bottom: 0.3em;
}